import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { IntlProvider } from "react-intl";
// import "bootstrap/dist/css/bootstrap.css";
const selectedLanguage = store.getState().language.selectedLanguage;

// Create an object that holds messages for all supported languages
// const messages = {
//   en: messagesEn,
//   // Add other languages as needed
// };
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <IntlProvider> */}
    <React.StrictMode>
      <App />
    </React.StrictMode>
    {/* </IntlProvider> */}
  </Provider>
);
