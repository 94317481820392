import React from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import { Link, animateScroll as scroll } from "react-scroll";
import useSmoothScroll from "../../common/Navbar/UseSmoothScroll";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../languageSlice";
import {
  bestOnlineEducationPlatform,
  bestOnlineEducationPlatformParagraph,
  getStartedButton,
  viewCourseButton,
} from "../../../utils/localizationConstants.js";
const Hero = () => {
  const selectedLanguage = useSelector(selectLanguage);
  useSmoothScroll();

  console.log(selectedLanguage);
  console.log(bestOnlineEducationPlatform.selectedLanguage);
  return (
    <>
      <section className="hero" style={{ marginBottom: "10vh" }}>
        <div className="container">
          <div className="row">
            <Heading
              // subtitle="WELCOME TO Alliance College"
              title={bestOnlineEducationPlatform[selectedLanguage]}
            />
            <p className="hero-para">
              {bestOnlineEducationPlatformParagraph[selectedLanguage]}
            </p>
            <div className="button">
              <button
                className="primary-btn"
                style={{ backgroundColor: "#7E7EED" }}
              >
                {getStartedButton[selectedLanguage]}
                <i className="fa fa-long-arrow-alt-right"></i>
              </button>
              <Link
                smooth={true}
                duration={500}
                to="courses"
                className="head-link"
              >
                <button style={{ color: "#6D6DF4" }}>
                  {viewCourseButton[selectedLanguage]}
                  <i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="margin"></div> */}
    </>
  );
};

export default Hero;
