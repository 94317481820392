import React from "react";
import { testimonal } from "../../../dummydata";
import Heading from "../../common/heading/Heading";
import "./style.css";
import { testimonial } from "../../../utils/localizationConstants";
import { useSelector } from "react-redux";

const Testimonal = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="testimonal padding" id="student">
        <div className="container">
          <Heading
            subtitle={testimonial[selectedLanguage]}
            // title="Our Successful Students"
          />

          <div className="content grid2">
            {testimonal.map((val) => (
              <div className="items shadow">
                <div className="box flex">
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + val.cover} alt="" />
                    <i className="fa fa-quote-left icon"></i>
                  </div>
                  <div className="name">
                    <h2>{val.name[selectedLanguage]}</h2>
                    <span>{val.post}</span>
                  </div>
                </div>
                <p>{val.desc[selectedLanguage]}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonal;
