import React from "react";
import Back from "../common/back/Back";
import BlogCard from "./BlogCard";
import "./blog.css";
import Heading from "../common/heading/Heading";
import { useSelector } from "react-redux";
import { exploreNews } from "../../utils/localizationConstants";

const Blog = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <Back title="Blog Posts" />
      <section className="blog padding news" id="news">
        <Heading subtitle={exploreNews[selectedLanguage]} />
        <div className="container grid2">
          <BlogCard />
        </div>
      </section>
    </>
  );
};

export default Blog;
