import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import "./Heading.css";
import { useSelector } from "react-redux";
const Span = styled.span`
  color: #fa0202;
  cursor: pointer;
  font-weight: 600;
  font-size: 50px;
`;
const roles = ["የቴክኒክና ሙያ ትምህርትና ሥልጠና", "የመጀመሪያ ዲግሪ ፕሮግራም", "የድህረ ምረቃ ፕሮግራም"];

const Heading = ({ subtitle, title }) => {
  return (
    <>
      <div
        id="heading"
        style={{
          boxSizing: "border-box",
          // overflow: "hidden",
          color: "#0000FE",
        }}
      >
        <h3 style={{ color: "black", fontSize: "3vw" }}>{subtitle}</h3>
        <Span style={{ color: "#FA0202" }}>
          {/* <Typewriter
            options={{
              strings: roles,
              autoStart: true,
              loop: true,
            }}
          /> */}
        </Span>
        <h1
          style={{
            color: "white",
            fontSize: "3vw",
          }}
        >
          {title}
        </h1>
      </div>
    </>
  );
};

export default Heading;
