import React from "react";
import "../blog/blog.css";
import { blog } from "../../dummydata";
import Heading from "../common/heading/Heading";
import { useSelector } from "react-redux";
import { blogTitle } from "../../utils/localizationConstants";

// copy code of blog => blogCard

const Hblog = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="blog" id="blog">
        <div className="container">
          <Heading
            subtitle={blogTitle[selectedLanguage]}
            // title="Recent From Blog"
          />
          <div className="grid2">
            {blog.slice(0, 3).map((val) => (
              <div className="items shadow" style={{ marginBottom: "5rem" }}>
                <div
                  className="img"
                  // style={{
                  //   backgroundImage:
                  //     "url(../../../../../public/images/blog/photo_2023-12-28_00-51-24.jpg)",
                  // }}
                >
                  <img
                    src={process.env.PUBLIC_URL + val.cover}
                    // height="100px"
                    alt=""
                  />
                </div>
                <div className="text">
                  {/* <div className="admin flexSB">
                    <span style={{ color: "#0000FE" }}>
                      <i className="fa fa-user"></i>
                      <label htmlFor="">{val.type}</label>
                    </span>
                    <span style={{ color: "#0000FE" }}>
                      <i className="fa fa-calendar-alt"></i>
                      <label htmlFor="">{val.date}</label>
                    </span>
                    <span style={{ color: "#0000FE" }}>
                      <i className="fa fa-comments"></i>
                      <label style={{ color: "#0000FE" }} htmlFor="">
                        {val.com}
                      </label>
                    </span>
                  </div> */}
                  <h1>{val.title[selectedLanguage]}</h1>
                  <p>{val.desc[selectedLanguage]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hblog;
