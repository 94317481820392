import {
  blogTitle1,
  blogTitle2,
  blogTitle3,
} from "./utils/localizationConstants";

export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    // title: "Technical and Vocational Education and Training (TVET)",
    title: {
      en: "Technical and Vocational Education and Training(TVET)",
      am: "የቴክኒክ እና ሙያ ትምህርት እና ስልጠና",
      fr: "Enseignement et formation techniques et professionnels",
      or: "Barnoota fi Leenjii Teeknikaa fi Ogummaa",
    },
    desc: {
      en: "We pride ourselves on providing quality education and training in different TVET programs, including Accounting, Information Technology, and Human Resource Management.",
      am: "በተለያዩ የቴክኒክና ሙያ ትምህርትና ሥልጠና ፕሮግራሞች በአካውንቲንግ፣ ኢንፎርሜሽን ቴክኖሎጂ እና የሰው ኃይል አስተዳደርን ጨምሮ ጥራት ያለው ትምህርትና ሥልጠና በመስጠት እራሳችንን እንኮራለን።",
      fr: "Nous sommes fiers de fournir une éducation et une formation de qualité dans différents programmes d'EFTP, notamment la comptabilité, les technologies de l'information et la gestion des ressources humaines.",
      or: "Sagantaa TVET adda addaa irratti barnootaa fi leenjii qulqullina qabu kennuudhaan, Herrega, Teeknooloojii Odeeffannoo, fi Bulchiinsa Humna Namaa dabalatee ni boonna",
    },
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: {
      en: "Undergraduate Program",
      am: "የመጀመሪያ ዲግሪ ፕሮግራም",
      fr: "Programme de premier cycle",
      or: "Sagantaa Digirii Jalqabaa",
    },
    desc: {
      en: "We offer three programs: Information Technology, Management, and Accounting and Finance.",
      am: "ሶስት ፕሮግራሞችን እናቀርባለን-ኢንፎርሜሽን ቴክኖሎጂ፣ አስተዳደር እና አካውንቲንግ እና ፋይናንስ",
      fr: "Nous proposons trois programmes : technologies de l'information, gestion et comptabilité et finance.",
      or: "Sagantaa sadii ni dhiheessina: Teeknooloojii Odeeffannoo, Hooggansaa, fi Herregaa fi Faayinaansii",
    },
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: {
      en: "Potgraduate Program",
      am: "የድህረ ምረቃ ፕሮግራም",
      fr: "Programme d'études supérieures",
      or: "Sagantaa Digirii Lammaffaa",
    },
    desc: {
      en: "We offer two programs: Masters of Business Administration (MBA) and Masters of Accounting and Finance.",
      am: "ሁለት ፕሮግራሞችን እናቀርባለን-የቢዝነስ አስተዳደር (MBA) እና ማስተር ኦፍ አካውንቲንግ እና ፋይናንስ።",
      fr: "Nous proposons deux programmes : Master en administration des affaires (MBA) et Master en comptabilité et finance.",
      or: "Sagantaa lama ni dhiheessina: Mastersii Bulchiinsa Bizinesii (MBA) fi Mastersii Herregaa fi Faayinaansii.",
    },
  },
];
export const awrapper = [
  {
    cover:
      "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000+",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "20+",
    title: "TRUSTED TUTORS",
  },
  {
    cover:
      "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "30+",
    title: "COURSES",
  },
];
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Management",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Alemayehu Sete",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Basic of Computer",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Demelash Baykedagn",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Networking",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saron Gebreyes",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "2000 Birr All Course",
    pricePer: "200 Birr per month",
  },
];
export const tevt = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "Accounting",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Information Technology",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Human Resource Management",
    course: "10 Courses",
  },
  // {
  //   cover: "./images/courses/online/o4.png",
  //   hoverCover: "./images/courses/online/o4.1.png",
  //   courseName: "History & Archeologic",
  //   course: "15 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o5.png",
  //   hoverCover: "./images/courses/online/o5.1.png",
  //   courseName: "Software Engineering",
  //   course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: "Information Software",
  //   course: "60 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: "Health & Fitness",
  //   course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Marketing",
  //   course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
];
export const Undergraduate = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: " Information Technology",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Management, and Accounting and Finance",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Software development",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Networking, and cybersecurity",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
];
export const postgratuate = [
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Masters of Business Administration (MBA)",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Masters of Accounting and Finance",
    course: "17 Courses",
  },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
];
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
];
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
];
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
];
export const blog = [
  {
    id: 1,
    type: "President of Alliance College",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: blogTitle1,
    desc: {
      am: `እነሆ ሌላ በረከት ከአንጋፋው አሊያንስ ኮሌጅ!
  
    አሊያንስ ኮሌጅ ለዓመታት እንዳስለመደው 
    √ በሐይማኖት ተቋማት 
    √ በጋሞ ልማት ማሕበር 
    √ በቀበሌያት እና 
    √ በከተማችን ዕድሮች ባሰራጨው ማስታወቂያ መሰረት ለሁሉም አቅመ ደካማ እና ረዳት የሌላቸው ተማሪዎች ክፍት የሆነ ውድድር በማድረግ መስፈርቱን ያሟሉ እና የመግቢያ ፈተናውን ያለፉ 100 ተማሪዎችን ማለትም 
    10 ተማሪዎች በቀን ዲግሪ 
    10 ተማሪዎች በማታው ዲግሪ
    10 ተማሪዎች በቀን ቴክኒክ እና ሙያ 
    10 ተማሪዎች በማታው ቴክኒክ እና ሙያ 
    60 ተማሪዎች በሪሚዲያል መርሃ ግብሮች የነፃ ትምህርት ዕድል ለመስጠት በይፋ ወደ ኮሌጁ ቀላቅሏል። 
    
    አሊያንስ! የዕውቀት ድግስ!
    `,
      en: `Here's another blessing from the legendary Alliance College! Alliance College, as it has been doing for years, √ by religious institutions √ by Gamo Development Association √ by Kebeleyat and √ by the opportunities of our city, based on the announcement that is open to all weak and helpless students, 100 students who meet the requirements and pass the entrance exam, namely 10 students in day degree, 10 students in Matau degree It has officially joined the college to provide scholarships to 10 students in Day Technical and Vocational and 60 students in Matu Technical and Vocational Remedial Programs. Alliance! A feast of knowledge!`,
      or: `Kunoo eebba biraa Kolleejjii Gamtaa beekamaa ta'e irraa! Kolleejjiin Alliance, akkuma waggootaaf hojjechaa ture, √ dhaabbilee amantaatiin √ Waldaa Misooma Gaamootiin √ Kebeleyat fi √ carraa magaalaa keenyaatiin, beeksisa barattoota dadhaboo fi gargaarsa hin qabne hundaaf banaa ta’e irratti hundaa’uun, barattoota 100 walga’an ulaagaalee fi qormaata seensaa darbuu, jechuunis barattoota 10 digirii guyyaa, barattoota 10 digrii Matau Barattoota 10f Teeknikaa fi Ogummaa Guyyaa fi barattoota 60 Sagantaa Sirreeffama Teeknikaa fi Ogummaa Matu irratti scholarship kennuuf ifatti kolleejjichatti makamee jira. Gamtaa! Ayyaana beekumsaa!`,
      fr: `Voici une autre bénédiction du légendaire Alliance College ! Alliance College, comme il le fait depuis des années, √ par les institutions religieuses √ par l'Association de Développement Gamo √ par Kebeleyat et √ par les opportunités de notre ville, sur la base de l'annonce ouverte à tous les étudiants faibles et impuissants, 100 étudiants qui se réuniront les exigences et réussir l'examen d'entrée, à savoir 10 étudiants en diplôme de jour, 10 étudiants en diplôme Matau. Il a officiellement rejoint le collège pour offrir des bourses à 10 étudiants en programme technique et professionnel de jour et à 60 étudiants en programmes de rattrapage technique et professionnel Matu. Alliance! Une fête de la connaissance !`,
    },
    cover: "/images/blog/photo_2023-12-28_00-51-35.jpg",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: blogTitle2,
    desc: {
      am: "ኮሌጅ በአንድ ሰው ህይወት ውስጥ የለውጥ ጊዜ ነው፣ አዳዲስ እድሎች፣ ልምዶች እና ጓደኝነት የወደፊት ህይወታችንን የሚቀርጹበት። በአልያንስ ቆይታዬን ሳሰላስል፣ ጎልቶ የሚታየው አንዱ ገጽታ በግቢው ማህበረሰብ ውስጥ ያለው አስደናቂ ልዩነት ነው። ይህ ልዩነት፣ የተለያዩ ባህሎችን፣ ዳራዎችን እና አመለካከቶችን ያካተተ፣ የኮሌጅ ልምዴን ስፍር ቁጥር በሌላቸው መንገዶች አበልጽጎታል። በዚህ ብሎግ በአሊያንስ ውስጥ ብዝሃነትን መቀበል እንዴት የአስተሳሰብ አድማሴን እንዳሰፋው ላካፍላችሁ እወዳለሁ።",
      or: "Kolleejjiin yeroo jireenya nama tokkoo keessatti jijjiirama fidu yoo ta’u, carraan haaraan, muuxannoowwanii fi hiriyyummaan egeree keenya itti bocu. Yeroon Alliance keessa ture yeroon yaadu, wanti adda ta’e tokko garaagarummaa hawaasa mooraa kanaa isa nama hin amansiifnedha. Aadaa, duubbee fi ilaalcha adda addaa dabalatee garaagarummaan kun muuxannoo kolleejjii koo karaa lakkoofsa hin qabneen badhaadhesseera. Biloogii kana keessatti garaagarummaa Gamtaa keessatti hammachuun akkamitti akka ilaalcha koo bal’ise isiniif qooduun barbaada.",
      fr: "L'université est une période de transformation dans la vie, où de nouvelles opportunités, expériences et amitiés façonnent notre avenir. Lorsque je réfléchis à mon passage à Alliance, un aspect qui ressort est l’incroyable diversité de la communauté du campus. Cette diversité, y compris des cultures, des origines et des perspectives différentes, a enrichi mon expérience universitaire d'innombrables façons. Dans ce blog, j'aimerais partager avec vous comment l'adoption de la diversité au sein d'Alliance a élargi mes horizons.",
      en: "College is a transformative time in one's life, where new opportunities, experiences, and friendships shape our future. As I reflect on my time at Alliance, one aspect that stands out is the incredible diversity of the campus community. This diversity, including different cultures, backgrounds, and perspectives, has enriched my college experience in countless ways. In this blog I would like to share with you how embracing diversity in Alliance has broadened my horizons.",
    },
    cover: "/images/blog/photo_2023-12-28_00-51-29.jpg",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: blogTitle3,
    desc: {
      am: "በአሊያንስ የኮሌጅ ጉዞዬ እጅግ አስደናቂ የሆነ ጀብዱ ነበር፣ በይበልጥ በልዩነት መታቀፍ የተቀረፀ። ደማቅ የባህል ልውውጥ፣ ምሁራዊ ንግግር፣ ጥልቅ ጓደኝነት እና የግል እድገቴ የኮሌጅ ልምዴን በእውነት ለውጥ አምጥቶታል። ከኮሌጅ ባለፈ አዳዲስ ስራዎችን ለመስራት ስዘጋጅ፣ከዚህ የተለያየ ማህበረሰብ የተማርኩትን ጠቃሚ ትምህርት ይዤ እሄዳለሁ። በአሊያንስ ውስጥ የተፈጠሩት ልምዶች እና ግንኙነቶች እንደሚመሩኝ እርግጠኛ ነኝ",
      or: "My college journey at Alliance has been an amazing adventure, shaped even more by the embrace of diversity. Vibrant cultural exchange, intellectual discourse, deep friendships, and personal growth have truly transformed my college experience. As I prepare to pursue new careers beyond college, I will take with me valuable lessons learned from this diverse community. I am sure that the experiences and relationships created in Alliance will guide me",
      fr: "Mon parcours universitaire à Alliance a été une aventure incroyable, encore plus façonnée par l’adhésion à la diversité. Des échanges culturels dynamiques, un discours intellectuel, des amitiés profondes et une croissance personnelle ont véritablement transformé mon expérience universitaire. Alors que je me prépare à poursuivre de nouvelles carrières au-delà de l’université, j’emporterai avec moi de précieuses leçons apprises de cette communauté diversifiée. Je suis sûr que les expériences et les relations créées au sein de l'Alliance me guideront",
      en: "My college journey at Alliance has been an amazing adventure, shaped even more by the embrace of diversity. Vibrant cultural exchange, intellectual discourse, deep friendships, and personal growth have truly transformed my college experience. As I prepare to pursue new careers beyond college, I will take with me valuable lessons learned from this diverse community. I am sure that the experiences and relationships created in Alliance will guide me",
    },
    cover: "/images/blog/photo_2023-12-28_00-51-39.jpg",
  },
  // {
  //   id: 4,
  //   type: "admin",
  //   date: "JAN. 02, 2022",
  //   com: "3 COMMENTS ",
  //   title: "የተማሪ ምዝገባ",
  //   desc: "የአሊያንስ ኮሌጅ ቤተሰብ አባል ለመሆን ይህን አስደናቂ እድል እንዳያመልጥዎ! ዛሬ በመመዝገብ ቦታዎን ያስጠብቁ። የማመልከቻ ሂደቱን ለመጀመር ወይም የአቀባበል ድባብን በገዛ እጃችን ለማየት የካምፓስ ጉብኝት ለማድረግ [የድር ጣቢያ ሊንክ] ላይ ድህረ ገጻችንን ይጎብኙ።",
  //   cover: "/images/blog/photo_2023-12-28_00-51-39.jpg",
  // },
  // {
  //   id: 5,
  //   type: "admin",
  //   date: "DEC. 14, 2022",
  //   com: "3 COMMENTS ",
  //   title: "የተማሪ ምዝገባ",
  //   desc: "የአሊያንስ ኮሌጅ ቤተሰብ አባል ለመሆን ይህን አስደናቂ እድል እንዳያመልጥዎ! ዛሬ በመመዝገብ ቦታዎን ያስጠብቁ። የማመልከቻ ሂደቱን ለመጀመር ወይም የአቀባበል ድባብን በገዛ እጃችን ለማየት የካምፓስ ጉብኝት ለማድረግ [የድር ጣቢያ ሊንክ] ላይ ድህረ ገጻችንን ይጎብኙ።",
  //   cover: "/images/blog/photo_2023-12-28_00-51-39.jpg",
  // },
  // {
  //   id: 6,
  //   type: "user",
  //   date: "JAN. 18, 2021",
  //   com: "12 COMMENTS ",
  //   title: "የተማሪ ምዝገባ",
  //   desc: "የአሊያንስ ኮሌጅ ቤተሰብ አባል ለመሆን ይህን አስደናቂ እድል እንዳያመልጥዎ! ዛሬ በመመዝገብ ቦታዎን ያስጠብቁ። የማመልከቻ ሂደቱን ለመጀመር ወይም የአቀባበል ድባብን በገዛ እጃችን ለማየት የካምፓስ ጉብኝት ለማድረግ [የድር ጣቢያ ሊንክ] ላይ ድህረ ገጻችንን ይጎብኙ።",
  //   cover: "/images/blog/photo_2023-12-28_00-51-39.jpg",
  // },
];
export const testimonal = [
  {
    id: 1,
    name: {
      am: "አብይ አድማሱ",
      en: "Abii Admaasuu",
      fr: "Abi Admasu",
      or: "Abiy Admaasuu",
    },
    post: "Accounting",
    desc: {
      am: "የኮሌጅ ህይወት በችግሮች፣ በድል አድራጊዎች እና በግል እድገት የተሞላ የለውጥ ጉዞ ነው። በዚህ ብሎግ የኮሌጅ ውጣ ​​ውረዶችን ብቻ ሳይሆን አስደናቂ ስኬት ያስመዘገቡ ሶስት አስደናቂ ተማሪዎችን ታሪክ በማካፈል ደስ ብሎናል። ልዩ መንገዶቻቸው፣ የማይናወጥ ቁርጠኝነት እና ለላቀነት ቁርጠኝነት ለሁሉም የኮሌጅ ተማሪዎች መነሳሳት ሆነው ያገለግላሉ።",
      en: "College life is a journey full of challenges, triumphs and personal growth. In this blog, we are pleased to share the stories of three amazing students who not only faced the ups and downs of college, but also achieved remarkable success. Their unique ways, unwavering determination and commitment to excellence serve as an inspiration to all college students.",
      fr: "La vie universitaire est un voyage plein de défis, de triomphes et de croissance personnelle. Dans ce blog, nous sommes heureux de partager les histoires de trois étudiants extraordinaires qui ont non seulement affronté les hauts et les bas de l’université, mais ont également obtenu des succès remarquables. Leurs méthodes uniques, leur détermination inébranlable et leur engagement envers l'excellence servent d'inspiration à tous les étudiants.",
      or: "Jireenyi kolleejjii imala qormaata, injifannoo fi guddina dhuunfaatiin guutameedha. Bilbila kana keessatti seenaa barattoota ajaa’ibsiisoo sadii kanneen bu’aa ba’ii kolleejjii mudachuu qofa osoo hin taane, milkaa’ina ajaa’ibaa argatan qooduu keenyatti gammadneerra. Karaan isaanii adda ta’e, kutannoon isaanii kan hin raafamnee fi kutannoon isaanii olaantummaaf qaban barattoota kolleejjii hundaaf kaka’umsa ta’ee tajaajila.",
    },
    cover: "/images/blog/photo_2023-12-28_00-51-17.jpg",
  },
  {
    id: 2,
    name: {
      am: "ማርቆስ መክብብ",
      en: "Mark's Ecclesiastes",
      fr: "L'Ecclésiaste de Marc",
      or: "Maarqoos Makbib",
    },
    post: "Management",
    desc: {
      am: "የኮሌጅ ጉዞ የተቀረፀው አማካሪነትን እና የግል እድገትን ለማሳደግ ባለው ቁርጠኝነት ነው። የአንደኛ ትውልድ የኮሌጅ ተማሪ ሆኖ በማያውቀው መሬት ላይ በመጓዝ የተለያዩ ፈተናዎችን ገጥሞታል። ሆኖም አሌክስ ከፕሮፌሰሮች መመሪያ ጠይቋል፣ የአማካሪ ፕሮግራሞችን ተቀላቀለ እና ለሙያዊ እድገት እድሎችን በንቃት ይፈልጋል። በነዚህ ልምዶች፣ የአካዳሚክ ስኬትን ብቻ ሳይሆን ጠንካራ የድጋፍ መረብን ዘርግቷል፣ አስፈላጊ ክህሎቶችን አዳብሯል እና እውነተኛነቱን አገኘ።",
      en: "College Journey is shaped by a commitment to fostering mentorship and personal growth. As a first-generation college student, he faces various challenges as he travels through unfamiliar terrain. However, Alex sought guidance from professors, joined mentoring programs, and actively sought opportunities for professional development. Through these experiences, he not only achieved academic success, but also built a strong support network, developed important skills, and found his true self.",
      fr: "College Journey est façonné par un engagement à favoriser le mentorat et la croissance personnelle. En tant qu'étudiant de première génération, il fait face à divers défis alors qu'il voyage sur un terrain inconnu. Cependant, Alex a demandé conseil à des professeurs, a rejoint des programmes de mentorat et a activement recherché des opportunités de développement professionnel. Grâce à ces expériences, il a non seulement réussi ses études, mais il a également bâti un solide réseau de soutien, développé des compétences importantes et trouvé sa vraie personnalité.",
      or: "Imalli Kolleejjii kan bocame gorsaa fi guddina dhuunfaa guddisuuf kutannoodhaan. Akka barattuu kolleejjii dhaloota tokkoffaatti, lafa hin baramne keessa yeroo deemu qormaata adda addaa isa mudata. Haa ta'u malee, Aleeksiin piroofeesaroota irraa qajeelfama barbaade, sagantaalee gorsaa keessatti makamee, carraa guddina ogummaa dammaqinaan barbaada ture. Muuxannoowwan kanaan milkaa’ina barnootaa argachuu qofa osoo hin taane, toora deeggarsa cimaa ijaaree, dandeettiiwwan barbaachisoo ta’an horatee, ofii isaa isa dhugaa argate.",
    },
    cover: "/images/blog/photo_2023-12-28_00-51-21.jpg",
  },
  {
    id: 3,
    name: {
      am: "አዳነ መክብብ",
      en: "Adaane Makbib",
      fr: "enregistré Ecclésiaste",
      or: "qusatame Lallaba",
    },
    post: "Information Technology",
    desc: {
      am: "ለባህል ልዩነት ጥልቅ አድናቆት ያለው ተማሪ፣ በኮሌጅ ዘመኑ ውስጥ አለም አቀፍ ተሳትፎን ተቀብሏል። በውጭ አገር ፕሮግራሞችን በማጥናት, ዓለም አቀፍ ልምምድ እና በመድብለ ባህላዊ ድርጅቶች ውስጥ በመሳተፍ, ዓለም አቀፋዊ አስተሳሰብ እና የተለያዩ ባህሎች ጥልቅ ግንዛቤን አዳብሯል. የዴንማርክ ተሞክሮዎች አመለካከቶቿን አስፍተውታል፣ ባሕላዊ አቋሟን አሻሽለዋል፣ እና እርስ በርስ በተገናኘ ዓለም ውስጥ እንድትበለጽግ መሣሪያዎችን አስታጥቋታል።",
      en: "A student with a deep appreciation for cultural diversity, he embraced international engagement throughout his college years. Through study abroad programs, international internships and participation in multicultural organizations, he has developed a global mindset and a deep understanding of different cultures. Dane's experiences have broadened her perspectives, enhanced her cross-cultural understanding, and equipped her with the tools to thrive in an interconnected world.",
      fr: "Étudiant profondément attaché à la diversité culturelle, il s’est engagé à l’international tout au long de ses années universitaires. Grâce à des programmes d'études à l'étranger, des stages internationaux et la participation à des organisations multiculturelles, il a développé une mentalité globale et une profonde compréhension des différentes cultures. Les expériences de Dane ont élargi ses perspectives, amélioré sa compréhension interculturelle et l'ont dotée des outils nécessaires pour prospérer dans un monde interconnecté.",
      or: "Barattuu garaagarummaa aadaa dinqisiifannaa guddaa qabu yoo ta’u, bara kolleejjii isaa hunda keessatti hirmaannaa idil-addunyaa hammateera. Sagantaa barnoota biyya alaa, leenjii idil-addunyaa fi dhaabbilee aadaa hedduu keessatti hirmaachuudhaan yaada addunyaa fi hubannoo gadi fagoo aadaa adda addaa horateera. Muuxannoon Deen ilaalcha ishee bal’isee, hubannoo aadaa qaxxaamuraa ishee guddiseera, akkasumas meeshaalee addunyaa walitti hidhamiinsa qabu keessatti akka guddattu isheef hidhachiiseera.",
    },
    cover: "/images/blog/photo_2023-12-28_00-51-24.jpg",
  },
];
