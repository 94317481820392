// src/components/LanguageSelector.js

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Select, MenuItem } from "@mui/material";
import { setLanguage, selectLanguage } from "../../languageSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectLanguage);

  const handleLanguageChange = (event) => {
    dispatch(setLanguage(event.target.value));
  };

  return (
    <FormControl>
      <Select
        value={selectedLanguage}
        onChange={handleLanguageChange}
        style={{ maxWidth: "100px" }}
      >
        <MenuItem value="en">English</MenuItem>
        {/* <MenuItem value="es">Español</MenuItem> */}
        <MenuItem value="am">አማርኛ</MenuItem>
        <MenuItem value="or">Afaan Oromoo</MenuItem>
        <MenuItem value="fr">French</MenuItem>
        {/* Add more languages as needed */}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
