import React, { useEffect, useState } from "react";
import {
  Nav,
  NavLink,
  NavbarContainer,
  Span,
  NavLogo,
  NavItems,
  GitHubButton,
  ButtonContainer,
  MobileIcon,
  MobileMenu,
  MobileNavLogo,
  MobileLink,
} from "./NavbarStyledComponent";
import { DiCssdeck, DiPython } from "react-icons/di";
import { FaBars } from "react-icons/fa";
import { Close, CloseRounded } from "@mui/icons-material";
import { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "./search.css";
import Dropdown from "./Dropdown";
import { About, Academics, Admissions, Life } from "./MenuItems";
import { useLocation, useNavigate } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LanguageSelector from "../LanguageSelector";
import {
  about,
  academics,
  admissions,
  allianceLogo,
  lifeAtAlliance,
  news,
  onlineCourseButton,
  research,
} from "../../../utils/localizationConstants";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();
  const [academicsDropdown, setAcademicsDropdown] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [admissionDropdown, setAdmissionDropdown] = useState(false);
  const [lifeDropdown, setLifeDropdown] = useState(false);
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  const history = useHistory();
  // const location = useLocation();
  // const currentPath = location.pathname;

  // Do something with the currentPath
  // console.log(currentPath);
  const onMouseEnter = (link) => {
    if (window.innerWidth < 960) {
      setAcademicsDropdown(false);
      setAboutDropdown(false);
      setAdmissionDropdown(false);
      setLifeDropdown(false);
      if (link == "courses") {
        history.push("/");
      }
      if (link == "blog") {
        history.push("/");
      }
      if (link == "online-learning") {
        history.push("/");
      }
    } else {
      switch (link) {
        case "academics":
          setAcademicsDropdown(true);
          break;
        case "about":
          setAboutDropdown(true);
          break;
        case "admission":
          setAdmissionDropdown(true);
          break;
        case "life":
          setLifeDropdown(true);
          break;
        case "courses":
          history.push("/");
          break;
        default:
          break;
      }
    }
  };

  const onMouseLeave = (link) => {
    switch (link) {
      case "academics":
        setAcademicsDropdown(false);
        break;
      case "about":
        setAboutDropdown(false);
        break;
      case "admission":
        setAdmissionDropdown(false);
        break;
      case "life":
        setLifeDropdown(false);
        break;
      default:
        break;
    }
  };
  const closeMobileMenu = () => setClick(false);

  const handleScroll = () => {
    const navbar = document.getElementById("navbar");
    if (navbar) {
      if (window.scrollY > 0) {
        navbar.classList?.add("sticky");
      } else {
        // navbar.classList?.remove("sticky");
        navbar.classList?.add("sticky");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Nav id="navbar">
      <NavbarContainer>
        <NavLogo to="/">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              marginBottom: "20;",
              cursor: "pointer",
            }}
          >
            <div
              className="logo-img"
              style={{ width: "100px", height: "90px", borderRadius: "50%" }}
            ></div>
            <Span>{allianceLogo[selectedLanguage]}</Span>
          </div>
        </NavLogo>
        <MobileIcon>
          <FaBars
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </MobileIcon>
        <NavItems>
          {/* <NavLink href='#skills'>Skills</NavLink>
          <NavLink href='#experience'>Experience</NavLink> */}

          <div
            className="nav-item"
            onMouseEnter={() => onMouseEnter("about")}
            onMouseLeave={() => onMouseLeave("about")}
          >
            <Link
              to="/about"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              {about[selectedLanguage]}
              <i className="fas fa-caret-down" />
            </Link>
            {aboutDropdown && <Dropdown type={About} link="about" />}
          </div>
          <div
            className="nav-item"
            onMouseEnter={() => onMouseEnter("academics")}
            onMouseLeave={() => onMouseLeave("academics")}
          >
            <Link
              to="/"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              {academics[selectedLanguage]} <i className="fas fa-caret-down" />
            </Link>
            {academicsDropdown && <Dropdown type={Academics} link="home" />}
          </div>
          <li
            className="nav-item"
            onMouseEnter={() => onMouseEnter("admission")}
            onMouseLeave={() => onMouseLeave("admission")}
          >
            <Link
              to="/admission"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              {admissions[selectedLanguage]} <i className="fas fa-caret-down" />
            </Link>
            {admissionDropdown && <Dropdown type={Admissions} />}
          </li>
          <div
            className="nav-item"
            onMouseEnter={() => onMouseEnter("life")}
            onMouseLeave={() => onMouseLeave("life")}
          >
            <Link
              to="/life"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              {lifeAtAlliance[selectedLanguage]}{" "}
              <i className="fas fa-caret-down" />
            </Link>
            {lifeDropdown && <Dropdown type={Life} />}
          </div>
          <div className="nav-item">
            <Link
              to="/journal"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              {news[selectedLanguage]}
            </Link>
            {/* {lifeDropdown && <Dropdown type={Life} />} */}
          </div>
          {/* <div className="nav-item">
            <a
              href="https://alliance-student-research.netlify.app"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-links"
            >
              Research
            </a>
          </div> */}
        </NavItems>
        <ButtonContainer>
          <GitHubButton
            style={{ backgroundColor: "#7E7EED" }}
            href="https://alliance-student-research.netlify.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            {research[selectedLanguage]}
          </GitHubButton>
        </ButtonContainer>
        <ButtonContainer>
          <GitHubButton href="/">
            {onlineCourseButton[selectedLanguage]}
          </GitHubButton>
        </ButtonContainer>
        <div className="language-selector">
          <LanguageSelector />
        </div>

        <Link to="/contact">
          <div className="contact-us" href="/contact"></div>
        </Link>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink
              href="/"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Home
            </MobileLink>
            <MobileLink
              href="/about"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              About
            </MobileLink>
            <MobileLink
              href="#courses"
              onMouseEnter={() => onMouseEnter("courses")}
              onMouseLeave={() => onMouseLeave("courses")}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Courses
            </MobileLink>
            <MobileLink
              href="/#blog"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              onMouseEnter={() => onMouseEnter("blog")}
              onMouseLeave={() => onMouseLeave("blog")}
            >
              Blogs
            </MobileLink>
            <MobileLink
              href="/journal"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              News
            </MobileLink>
            <MobileLink
              href="#online-learning"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              onMouseEnter={() => onMouseEnter("online-learning")}
              onMouseLeave={() => onMouseLeave("online-learning")}
            >
              Upcomming Online
            </MobileLink>
            <MobileLink
              href="https://alliance-student-research.netlify.app"
              target="_blank"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Reserach
            </MobileLink>
            {/* <GitHubButton
              style={{
                padding: "10px 16px",
                background: "#0099ff",
                color: "white",
                width: "max-content",
              }}
              href="/contact"
              target="_blank"
            >
              Contact Us
            </GitHubButton> */}
          </MobileMenu>
        )}
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
