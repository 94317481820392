import React from "react";
import OnlineCourses from "../allcourses/OnlineCourses";
import Heading from "../common/heading/Heading";
import "../allcourses/courses.css";
import { coursesCard } from "../../dummydata";
import { useSelector } from "react-redux";
import { upcommingCoursesTitle } from "../../utils/localizationConstants";

const HAbout = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="homeAbout" id="online-learning">
        <div className="container">
          <Heading subtitle={upcommingCoursesTitle[selectedLanguage]} />

          <div className="coursesCard">
            {/* copy code form  coursesCard */}
            <div className="grid2">
              {coursesCard.slice(0, 3).map((val) => (
                <div className="items">
                  <div className="content flex">
                    <div className="left">
                      <div
                        className="img"
                        style={{ backgroundColor: "#3737f5" }}
                      >
                        <img src={val.cover} alt="" />
                      </div>
                    </div>
                    <div className="text">
                      <h1>{val.coursesName}</h1>
                      <div className="rate" style={{ color: "yellow" }}>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <label htmlFor="">(5.0)</label>
                      </div>
                      <div className="details">
                        {val.courTeacher.map((details) => (
                          <>
                            <div className="box">
                              <div className="dimg">
                                <img src={details.dcover} alt="" />
                              </div>
                              <div className="para">
                                <h4>--------</h4>
                              </div>
                            </div>
                            <span style={{ color: "#0000FE" }}>
                              {details.totalTime}
                            </span>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* <div className="price">
                    <h3 style={{ color: "#0000FE" }}>
                      {val.priceAll} / {val.pricePer}
                    </h3>
                  </div> */}
                  <button
                    className="outline-btn"
                    style={{ color: "#5757ff", marginTop: "20px" }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#fff"; // Change color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#0000FE"; // Change color back on hover exit
                    }}
                  >
                    Upcoming{" "}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <OnlineCourses />
      </section>
    </>
  );
};

export default HAbout;
