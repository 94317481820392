import React, { useState } from "react";
import "./Dropdown.css";
import useSmoothScroll from "./UseSmoothScroll";
// import { Link } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { useHistory } from "react-router-dom";

function Dropdown({ type, link }) {
  const [click, setClick] = useState(false);
  const history = useHistory();
  if (link == "home") {
    history.push("/");
  }
  if (link == "about") {
    history.push("/about");
  } else {
    history.push("/");
  }
  if (link == "life") {
    history.push("/");
  }
  useSmoothScroll();
  const handleClick = () => setClick(!click);
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menuclicked" : "dropdown-menu"}
        style={{
          boxShadow: "1rem 0.5rem 1rem 0.5rem rgba(0, 0, 0, 0.15)",
        }}
      >
        {type.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
                smooth={true} // Enable smooth scrolling
                duration={500} // Duration of the scroll animatio
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
