import React from "react";
import "./about.css";
import Back from "../common/back/Back";
import AboutCard from "./AboutCard";
import Abouttt from "./Abouttt";

const About = () => {
  return (
    <>
      <Back title="About Us" />
      <Abouttt />
      {/* <AboutCard /> */}
    </>
  );
};

export default About;
