import React from "react";
import "./courses.css";
import { Undergraduate, online, postgratuate, tevt } from "../../dummydata";
import Heading from "../common/heading/Heading";
import { useSelector } from "react-redux";
import {
  ourCourses,
  postgratautePrograms,
  tvetPrograms,
  undergratuatePrograms,
} from "../../utils/localizationConstants";

const OnlineCourses = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="online" id="courses">
        <div className="container" id="tvet">
          <Heading
            subtitle={ourCourses[selectedLanguage]}
            // title=""
          />
          <h3 style={{ marginBottom: "2rem", marginTop: "1rem" }}>
            {tvetPrograms[selectedLanguage]}
          </h3>
          <div
            className="content grid3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {tevt.map((val) => (
              <div className="box">
                <div className="img">
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt="" className="show" />
                </div>
                <h1>{val.courseName}</h1>
                <span id="undergraduate">{val.course}</span>
              </div>
            ))}
          </div>
          <h3 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
            {undergratuatePrograms[selectedLanguage]}
          </h3>
          <div className="content grid3">
            {Undergraduate.map((val) => (
              <div className="box">
                <div className="img">
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt="" className="show" />
                </div>
                <h1>{val.courseName}</h1>
                <span id="post-graduate">{val.course}</span>
              </div>
            ))}
          </div>
          <h3 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
            {postgratautePrograms[selectedLanguage]}
          </h3>
          <div
            className="content grid3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {postgratuate.map((val) => (
              <div className="box">
                <div className="img">
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt="" className="show" />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineCourses;
