import React from "react";
import Heading from "../common/heading/Heading";
import "./about.css";
import { homeAbout } from "../../dummydata";
import Awrapper from "./Awrapper";
import { useSelector } from "react-redux";
import {
  leanAnyThing,
  programAndCourses,
} from "../../utils/localizationConstants";

const AboutCard = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="aboutHome">
        <div className="container flexSB">
          <div
            className="left row back-img"
            style={{ backgroundSize: "cover" }}
          >
            {/* <img
              className="about-back-img"
              // src="../../../public/images/alliance/photo_2023-12-26_17-34-54.jpg"
              alt=""
              style={{ maxHeight: "60%", marginTop: "3rem" }}
            /> */}
          </div>
          <div className="right row">
            <Heading
              subtitle={leanAnyThing[selectedLanguage]}
              title={
                <span style={{ backgroundColor: "#7E7EED", padding: "0 5px" }}>
                  {programAndCourses[selectedLanguage]}
                </span>
              }
              type=""
            />
            <div className="items">
              {homeAbout.map((val) => {
                return (
                  <div className="item flexSB">
                    <div className="img">
                      <img src={val.cover} alt="" />
                    </div>
                    <div className="text">
                      <h2>{val.title[selectedLanguage]}</h2>
                      <p className="para">{val.desc[selectedLanguage]}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  );
};

export default AboutCard;
