export const allianceLogo = {
  en: "ALLIANCE COLLEGE",
  am: "አሊያንስ ኮሌጅ",
  fr: "COLLÈGE DES ALLIANCES",
  or: "KOLLEEJJII ALLIANCE",
};

//nav links
export const academics = {
  en: "Academics",
  am: "አካዳሚክ",
  fr: "Universitaires",
  or: "Barnoota Idilee",
};
export const about = {
  en: "About Alliance",
  am: "ስለ አሊያንስ",
  fr: "À propos de l'Alliance",
  or: "Waa'ee Gamtaa",
};
export const admissions = {
  en: "Admissions",
  am: "መግቢያዎች",
  fr: "Admissions",
  or: "Seensa",
};
export const lifeAtAlliance = {
  en: "Life At Alliance",
  am: "ሕይወት በአሊያንስ",
  fr: "La vie à l'Alliance",
  or: "Jireenya Gamtaa Keessatti",
};
export const news = {
  en: "News",
  am: "ዜና",
  fr: "Nouvelles",
  or: "Oduu",
};
export const research = {
  en: "Research",
  am: "ምርምር",
  fr: "Recherche",
  or: "Qo'annoo",
};
export const onlineCourseButton = {
  en: "Online Courses",
  am: "የመስመር ላይ ኮርሶች",
  fr: "Cours en ligne",
  or: "Koorsii Toora Interneetii",
};
export const bestOnlineEducationPlatform = {
  en: "Best Online Education Platform",
  am: "ምርጥ የመስመር ላይ ትምህርት መድረክ",
  fr: "Meilleure plateforme d'éducation en ligne",
  or: "Waltajjii Barnoota Toora Interneetii Irra Caalu",
};

export const bestOnlineEducationPlatformParagraph = {
  en: "we believe in the power of education to transform lives and shape a better future. we provide a vibrant and inclusive learning environment for students to thrive.",
  am: "ሕይወትን ለመለወጥ እና የተሻለ የወደፊት ሁኔታን ለመቅረጽ በትምህርት ኃይል እናምናለን። ለተማሪዎች እንዲበለጽጉ ንቁ እና አካታች የትምህርት አካባቢን እናቀርባለን።",
  fr: "nous croyons au pouvoir de l’éducation pour transformer des vies et façonner un avenir meilleur. nous offrons un environnement d'apprentissage dynamique et inclusif permettant aux étudiants de s'épanouir",
  or: "humna barnoonni jireenya jijjiiruu fi egeree fooyya’aa bocuu danda’utti ni amanna. barattoonni akka dagaaganiif haala barumsaa socho’aa fi hunda hammate ni kennina",
};

export const getStartedButton = {
  en: "GET STARTED NOW",
  am: "አሁን ጀምር",
  fr: "COMMENCEZ MAINTENANT",
  or: "AMMA JALQABAA",
};
export const viewCourseButton = {
  en: "VIEW COURSE",
  am: "ኮርስ ለማየት",
  fr: "VOIR LE COURS",
  or: "KOORSII ILAA LAA",
};

// home about

export const leanAnyThing = {
  en: "LEARN ANYTHING",
  am: "ማንኛውንም ነገር ይማሩ",
  fr: "APPRENDRE TOUT",
  or: "WAAN KAMIYYUU BARADHAA",
};

export const programAndCourses = {
  en: "Programs and Courses",
  am: "ፕሮግራሞች እና ኮርሶች",
  fr: "Programmes et cours",
  or: "Sagantaa fi Koorsii",
};

export const tvetProgram = {
  en: "Technical and Vocational Education and Training(TVET)",
  am: "የቴክኒክ እና ሙያ ትምህርት እና ስልጠና",
  fr: "Enseignement et formation techniques et professionnels",
  or: "Barnoota fi Leenjii Teeknikaa fi Ogummaa",
};
export const tvetProgramParagraph = {
  en: "We pride ourselves on providing quality education and training in different TVET programs, including Accounting, Information Technology, and Human Resource Management.",
  am: "በተለያዩ የቴክኒክና ሙያ ትምህርትና ሥልጠና ፕሮግራሞች በአካውንቲንግ፣ ኢንፎርሜሽን ቴክኖሎጂ እና የሰው ኃይል አስተዳደርን ጨምሮ ጥራት ያለው ትምህርትና ሥልጠና በመስጠት እራሳችንን እንኮራለን።",
  fr: "Nous sommes fiers de fournir une éducation et une formation de qualité dans différents programmes d'EFTP, notamment la comptabilité, les technologies de l'information et la gestion des ressources humaines.",
  or: "Sagantaa TVET adda addaa irratti barnootaa fi leenjii qulqullina qabu kennuudhaan, Herrega, Teeknooloojii Odeeffannoo, fi Bulchiinsa Humna Namaa dabalatee ni boonna",
};

export const undergratuateProgram = {
  en: "Undergraduate Program",
  am: "የመጀመሪያ ዲግሪ ፕሮግራም",
  fr: "Programme de premier cycle",
  or: "Sagantaa Digirii Jalqabaa",
};
export const undergraduateProgramParagraph = {
  en: "We offer three programs: Information Technology, Management, and Accounting and Finance.",
  am: "ሶስት ፕሮግራሞችን እናቀርባለን-ኢንፎርሜሽን ቴክኖሎጂ፣ አስተዳደር እና አካውንቲንግ እና ፋይናንስ",
  fr: "Nous proposons trois programmes : technologies de l'information, gestion et comptabilité et finance.",
  or: "Sagantaa sadii ni dhiheessina: Infoormeeshin teeknooloojii, Maaneejimeantii, fi Qkkaawuntiigii fi Faayinaansii",
};

export const postgratauteProgram = {
  en: "Potgraduate Program",
  am: "የድህረ ምረቃ ፕሮግራም",
  fr: "Programme d'études supérieures",
  or: "Sagantaa Digirii Lammaffaa",
};
export const postgratuateProgramParagraph = {
  en: "We offer two programs: Masters of Business Administration (MBA) and Masters of Accounting and Finance.",
  am: "ሁለት ፕሮግራሞችን እናቀርባለን-የቢዝነስ አስተዳደር (MBA) እና ማስተር ኦፍ አካውንቲንግ እና ፋይናንስ።",
  fr: "Nous proposons deux programmes : Master en administration des affaires (MBA) et Master en comptabilité et finance.",
  or: "Sagantaa lama ni dhiheessina: Mastersii Bulchiinsa Bizinesii (MBA) fi Mastersii Herregaa fi Faayinaansii.",
};

export const upcommingCoursesTitle = {
  en: "EXPLORE OUR UP COMMING ONLINE COURSES",
  am: "የሚመጡትን የመስመር ላይ ኮርሶችን ያስሱ",
  fr: "EXPLOREZ NOS PROCHAINS COURS EN LIGNE",
  or: "KOORSII GARE FUUL DURAATTI KARAA TOORE INTERNEETIIN KENNINU ILAALAA",
};

export const underGratuate = {
  en: "BROWSE OUR COURSES",
  am: "ኮርሶቻችንን ያስሱ",
  fr: "PARCOUREZ NOS COURS",
  or: "KOORSII KEENYA DROWSE",
};

export const ourCourses = {
  en: "BROWSE OUR COURSES",
  am: "ኮርሶቻችንን ያስሱ",
  fr: "PARCOUREZ NOS COURS",
  or: "KOORSII KEENYA DROWSE",
};

export const tvetPrograms = {
  en: "Technical and Vocational Education and Trainings(TVET)",
  am: "የቴክኒክ እና ሙያ ትምህርት እና ስልጠና ፕሮግራሞች",
  fr: "Enseignement et formation techniques et professionnels",
  or: "Barnoota fi Leenjii Teeknikaa fi Ogummaa",
};

export const undergratuatePrograms = {
  en: "UNDERGRADUATE PROGRAMS",
  am: "የድህረ ምረቃ ፕሮግራሞች",
  fr: "PROGRAMMES DE PREMIER CYCLE",
  or: "SAGANTAALEE DURII JALQABAA",
};
export const postgratautePrograms = {
  en: "POSTGRADUATE PROGRAMS",
  am: "የድህረ-ምረቃ ፕሮግራሞች",
  fr: "PROGRAMMES DE POSSIBILITÉ",
  or: "SAGANTAALEE EEGGAMA BOODAA",
};
export const testimonial = {
  en: "TESTIMONIAL",
  am: "ምስክርነት",
  fr: "TÉMOIGNAGE",
  or: "DHUGAA BA'AA",
};

export const blogTitle = {
  en: "OUR BLOG",
  am: "የእኛ ብሎግ",
  fr: "NOTRE BLOG",
  or: "BLOG KEENYA",
};

export const blogTitle1 = {
  en: "Embracing a New Academic Year at Alliance",
  am: "በአሊያንስ አዲስ የትምህርት ዓመትን መቀበል",
  fr: "Embrasser une nouvelle année universitaire à Alliance",
  or: "Bara Barnootaa Haaraa Alliance keessatti hammachuu",
};
export const blogTitle2 = {
  en: "Student Skill Development",
  am: "የተማሪ ክህሎት እድገት",
  fr: "Développement des compétences des étudiants",
  or: "Guddina Dandeettii Barattootaa",
};
export const blogTitle3 = {
  en: "Build Best Educational Environment",
  am: "ምርጥ የትምህርት አካባቢን ይገንቡ",
  fr: "Créer le meilleur environnement éducatif",
  or: "Haala Barnootaa Irra Caalu Ijaaruu",
};
export const aboutUsTitle = {
  en: "ABOUT US",
  am: "ስለ እኛ",
  fr: "À PROPOS DE NOUS",
  or: "WAA'EE KEENYA",
};
export const aboutUsParagraph = {
  en: "Alliance College is a young generation educational institution that has been providing top-quality education since its establishment in 2019. Our mission is to empower the generation with knowledge, skills, and values that will enable them to become successful professionals and valuable contributors to society. As a young generation institution, Alliance College has consistently been recognized as a center of academic excellence and reputation. Our institution has a strong commitment to providing a well-rounded education that fosters critical thinking, creativity, and a global perspective. Our students are not only equipped with the necessary subject matter expertise but also with the ability to adapt to the ever-changing demands of the professional world. At Alliance College, we deeply value integrity, inclusivity, and innovation. We believe in creating a supportive and inclusive learning environment that encourages personal and intellectual growth. Our faculty members are passionate educators who are dedicated to the success of our students. They bring their expertise and real-world experience into the classroom, ensuring that students receive a practical education that prepares them for their future careers.",
  am: "አሊያንስ ኮሌጅ በ2019 ከተመሠረተ ጀምሮ ከፍተኛ ጥራት ያለው ትምህርት እየሰጠ ያለ ወጣት ትውልድ የትምህርት ተቋም ነው።የእኛ ተልእኮ ትውልዱን በእውቀት፣በክህሎት እና እሴት በማብቃት ውጤታማ ባለሙያ እና ለህብረተሰቡ ጠቃሚ አስተዋፅዖ አበርክቷል። እንደ ወጣት ትውልድ ተቋም፣ አሊያንስ ኮሌጅ የአካዳሚክ የልህቀት እና መልካም ስም ማዕከል ሆኖ በቋሚነት እውቅና አግኝቷል። ተቋማችን ሂሳዊ አስተሳሰብን፣ ፈጠራን እና አለም አቀፋዊ እይታን የሚያበረታታ የተሟላ ትምህርት ለመስጠት ቁርጠኝነት አለው። ተማሪዎቻችን አስፈላጊው የርእሰ ጉዳይ እውቀት ብቻ ሳይሆን በየጊዜው ከሚለዋወጠው የባለሙያ አለም ፍላጎቶች ጋር መላመድ የሚችሉ ናቸው። በአሊያንስ ኮሌጅ፣ ታማኝነትን፣ አካታችነትን እና ፈጠራን በጥልቅ እናከብራለን። የግል እና የአዕምሮ እድገትን የሚያበረታታ አጋዥ እና አካታች የትምህርት አካባቢን በመፍጠር እናምናለን። የኛ መምህራን አባላት ለተማሪዎቻችን ስኬት ያደሩ ቀናተኛ አስተማሪዎች ናቸው። ተማሪዎች ለወደፊት ስራቸው የሚያዘጋጃቸውን ተግባራዊ ትምህርት እንዲያገኙ በማድረግ እውቀታቸውን እና የገሃዱ አለም ልምዳቸውን ወደ ክፍል ውስጥ ያመጣሉ ።",
  fr: "Alliance College est un établissement d'enseignement de la jeune génération qui offre un enseignement de qualité supérieure depuis sa création en 2019. Notre mission est de doter la génération de connaissances, de compétences et de valeurs qui lui permettront de devenir des professionnels à succès et de précieux contributeurs à la société. En tant qu'institution de jeune génération, Alliance College a toujours été reconnue comme un centre d'excellence académique et de réputation. Notre institution s'engage fermement à fournir une éducation complète qui favorise la pensée critique, la créativité et une perspective mondiale. Nos étudiants sont non seulement dotés de l'expertise nécessaire dans le domaine, mais également de la capacité de s'adapter aux exigences en constante évolution du monde professionnel. À Alliance College, nous valorisons profondément l’intégrité, l’inclusivité et l’innovation. Nous croyons en la création d’un environnement d’apprentissage favorable et inclusif qui encourage la croissance personnelle et intellectuelle. Nos professeurs sont des éducateurs passionnés qui se consacrent à la réussite de nos étudiants. Ils apportent leur expertise et leur expérience du monde réel en classe, garantissant ainsi que les étudiants reçoivent une formation pratique qui les prépare à leur future carrière.",
  or: "Alliance College dhaabbata barnootaa dhaloota dargaggootaa bara 2019 erga hundeeffamee kaasee barnoota qulqullina olaanaa qabu kennaa kan ture yoo ta’u, ergamni keenya dhaloonni beekumsa, dandeettii, fi gatiiwwan ogeessota milkaa’oo fi hawaasaaf gumaacha gatii guddaa qaban akka ta’an dandeessisan humneessuudha. Akka dhaabbata dhaloota dargaggootaatti, Kolleejjiin Alliance yeroo hunda wiirtuu gahumsa barnootaa fi maqaa gaarii ta’uun beekamtii argachaa tureera. Dhaabbanni keenya barnoota haala gaariin yaada qeeqaa, kalaqa, fi ilaalcha addunyaa guddisu kennuudhaaf kutannoo cimaa qaba. Barattoonni keenya ogummaa gosa barnootaa barbaachisaa ta’e qofa osoo hin taane gaaffii addunyaa ogummaa yeroo hunda jijjiiramaa jiruun walsimsiisuu danda’u. Kolleejjii Alliance keessatti, qulqullina, hunda hammatee fi kalaqaaf iddoo guddaa kennina. Haala barumsaa deggersaa fi hunda hammate kan guddina dhuunfaa fi sammuu jajjabeessu uumuutti ni amanna. Miseensonni faakiultii keenyaa barsiisota quuqama qaban kanneen milkaa’ina barattoota keenyaaf of kennaniidha. Ogummaa fi muuxannoo dhugaa isaanii daree keessatti fiduudhaan barattoonni barnoota qabatamaa hojii isaanii gara fuula duraatiif isaan qopheessu akka argatan ni taasisa.",
};

export const exploreNews = {
  en: "Explore recent news",
  am: "የቅርብ ጊዜ ዜናዎችን ያስሱ",
  fr: "Découvrez les actualités récentes",
  or: "Oduu dhiheenya kanaa qoradhu",
};

export const footerNewsLetter = {
  en: "Newsletter - Stay tune and get the latest update",
  am: "ጋዜጣ - ተከታተሉ እና የቅርብ ጊዜውን ዝመና ያግኙ",
  fr: "Newsletter - Restez à l'écoute et recevez la dernière mise à jour",
  or: "Barruu Oduu - Nu hordofaa, odeeffannoo haaraa argadhaa",
};

export const footerAllianceheading = {
  en: "ALLIANCE COLLEGE",
  am: "አሊያንስ ኮሌጅ",
  fr: "COLLÈGE DES ALLIANCES",
  or: "KOLLEEJJII ALLIANCE",
};
export const footerAllianceTitleLearnAnyThing = {
  en: "ONLINE EDUCATION & LEARNING",
  am: "የመስመር ላይ ትምህርት",
  fr: "ÉDUCATION ET APPRENTISSAGE EN LIGNE",
  or: "BARNOOTA FI BARNOOTA ONLINE",
};

export const footerAllianceParagraph = {
  en: "Alliance College is a young generation educational institution that has been providing top-quality education since its establishment in 2019.",
  am: "አሊያንስ ኮሌጅ በ2019 ከተመሠረተ ጀምሮ ከፍተኛ ጥራት ያለው ትምህርት እየሰጠ ያለ ወጣት ትውልድ የትምህርት ተቋም ነው።",
  fr: "Alliance College est un établissement d'enseignement de jeune génération qui propose un enseignement de qualité supérieure depuis sa création en 2019.",
  or: "Kolleejjiin Alliance dhaabbata barnootaa dhaloota dargaggootaa yoo ta’u, bara 2019 erga hundeeffamee kaasee barnoota qulqullina olaanaa qabu kennaa ture.",
};

export const footerQuestion = {
  en: "Have a Questions?",
  am: "ጥያቄዎች አሉዎት?",
  fr: "Vous avez des questions?",
  or: "Gaaffii Qabduu?",
};

export const footerRecent = {
  en: "Recent Post",
  am: "የቅርብ ጊዜ ልጥፍ",
  fr: "Post récent",
  or: "Maxxansa Dhiheenyaa",
};
