import React from "react";
import { useLocation } from "react-router-dom";
import Heading from "../heading/Heading";
import { useSelector } from "react-redux";
import {
  bestOnlineEducationPlatform,
  bestOnlineEducationPlatformParagraph,
  getStartedButton,
  viewCourseButton,
} from "../../../utils/localizationConstants";

const Back = ({ title }) => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  const location = useLocation();

  return (
    <>
      <section className="hero" style={{ position: "static" }}>
        <div className="container">
          <div className="row">
            <Heading
              // subtitle="WELCOME TO Alliance College"
              title={bestOnlineEducationPlatform[selectedLanguage]}
            />
            <p className="hero-para">
              {bestOnlineEducationPlatformParagraph[selectedLanguage]}
            </p>
            <div className="button">
              <button
                className=""
                style={{ backgroundColor: "#7E7EED", color: "#fff" }}
              >
                {getStartedButton[selectedLanguage]}{" "}
                <i className="fa fa-long-arrow-alt-right"></i>
              </button>
              <button style={{ color: "#7E7EED" }}>
                {" "}
                {viewCourseButton[selectedLanguage]}{" "}
                <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="margin"></div> */}
    </>
  );
};

export default Back;
