import React, { useState } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import axios from "axios";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
const Contact = () => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   subject: "",
  //   incomingMessage: "",
  // });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log({ ...formData });
  //   try {
  //     await axios.post(
  //       "https://nodemailer-example.onrender.com/api/user/email",
  //       formData
  //     );
  //     alert("Email sent successfully!");
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //     alert("Error sending email. Please try again later.", error);
  //     console.log(error);
  //   }
  // };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_v0rfqcq",
        "template_lqe4m9o",
        form.current,
        "BJi7eIxWhMwysxuNn"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      {/* <Back title="Contact us" /> */}
      <section className="contacts padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d495.9687212581689!2d37.55698237448531!3d6.029042664097715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2set!4v1703357409678!5m2!1sen!2set"
              title="Alliance"
              style={{
                width: "600",
                height: "600px",
                style: "border:0",
                allowfullscreen: "",
                loading: "lazy",
                referrerpolicy: "no-referrer-when-downgrade",
              }}
            ></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for any suggestion</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>
                  Arba Minch, Ethiopia Sikela Sub-city At the entrance of the
                  New Bubu Market
                </p>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p> admin@alliancecollege.edu.et</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p> + 1235 2355 98</p>
              </div>
            </div>

            <form action="" ref={form} onSubmit={sendEmail}>
              <div className="flexSB">
                <input
                  type="text"
                  name="user_name"
                  placeholder="Name"
                  // value={formData.name}
                  // onChange={handleChange}
                />
                <input
                  type="email"
                  name="user_email"
                  placeholder="Email"
                  // value={formData.email}
                  // onChange={handleChange}
                />
              </div>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                // value={formData.subject}
                // onChange={handleChange}
              />
              <textarea
                cols="30"
                rows="10"
                name="message"
                // value={formData.message}
                // onChange={handleChange}
              >
                Create a message here...
              </textarea>
              <button
                className="primary-btn"
                type="submit"
                style={{ backgroundColor: "#0000fe" }}
              >
                SEND MESSAGE
              </button>
            </form>

            <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
