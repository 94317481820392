import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";
import { useSelector } from "react-redux";
import {
  footerAllianceParagraph,
  footerAllianceTitleLearnAnyThing,
  footerAllianceheading,
  footerNewsLetter,
  footerQuestion,
  footerRecent,
} from "../../../utils/localizationConstants";
import LanguageSelector from "../LanguageSelector";

const Footer = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section className="newletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>{footerNewsLetter[selectedLanguage]}</h1>
            <span>Far far away, behind the word mountains</span>
          </div>
          <div className="right row">
            <input type="text" placeholder="Enter email address" />
            <i className="fa fa-paper-plane"></i>
          </div>
        </div>
      </section>
      <footer>
        <div className="container padding">
          <div className="box logo">
            <h1>{footerAllianceheading[selectedLanguage]}</h1>
            <span>{footerAllianceTitleLearnAnyThing[selectedLanguage]}</span>
            <p>{footerAllianceParagraph[selectedLanguage]}</p>

            <a href="http://www.facebook.com/Alliance-College-Arba-Minch">
              <i className="fab fa-facebook-f icon"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/alliance-college-arba-minch"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in icon"></i>
            </a>
            <i className="fab fa-telegram icon"></i>
            {/* <i className="fab fa-instagram icon"></i> */}
            <i className="fab fa-twitter icon"></i>
            {/* <i className="fab fa-youtube icon"></i> */}
          </div>
          <div className="box link">
            <h3></h3>
            <ul>
              {/* <li>About Us</li>
              <li>Services</li>
              <li>Courses</li>
              <li>Blog</li>
              <li>Contact us</li> */}
            </ul>
          </div>
          {/* <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div> */}
          <div className="box">
            <h3>{footerRecent[selectedLanguage]}</h3>
            {blog.slice(0, 3).map((val) => (
              <div className="items flexSB">
                <div className="img">
                  <img src={val.cover} alt="" />
                </div>
                <div className="text">
                  <span>
                    <i className="fa fa-calendar-alt"></i>
                    <label htmlFor="">{val.date}</label>
                  </span>
                  <span>
                    <i className="fa fa-user"></i>
                    <label htmlFor="">{val.type}</label>
                  </span>
                  <h4>{val.title[selectedLanguage].slice(0, 40)}...</h4>
                </div>
              </div>
            ))}
          </div>
          <div className="box last">
            <h3>{footerQuestion[selectedLanguage]}</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                Alliance College, Ethiopia, Arbaminch
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                +251 912 156 110
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                info@alliance.com
              </li>
            </ul>
          </div>
          <div className="language-selector">
            <LanguageSelector />
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2023 All rights reserved | This website is made by Alliance
          software team
        </p>
      </div>
    </>
  );
};

export default Footer;
