export const Academics = [
  {
    title: "Online Learning",
    path: "online-learning",
    cName: "dropdown-link",
    rout: "home",
  },
  {
    title: "Research",
    path: "/alliance-student-research.netlify.app",
    cName: "dropdown-link",
    rout: "home",
  },
  {
    title: "PostGraduate Programs",
    path: "post-graduate",
    cName: "dropdown-link",
    rout: "home",
  },
  {
    title: "UnderGraduate Programs",
    path: "undergraduate",
    cName: "dropdown-link",
    rout: "home",
  },
  {
    title: "TVET Programs",
    path: "tvet",
    cName: "dropdown-link",
    rout: "home",
  },
  {
    title: "Faculty And Stuffs",
    path: "#fuculty-stuff",
    cName: "dropdown-link",
    rout: "home",
  },
];

export const About = [
  {
    title: "Our Stories",
    path: "about",
    cName: "dropdown-link",
    rout: "about",
  },
  {
    title: "Mission And Vision",
    path: "/research",
    cName: "dropdown-link",
    rout: "about",
  },
  {
    title: "Core Values",
    path: "/post-graduate",
    cName: "dropdown-link",
    rout: "about",
  },
];

export const Admissions = [
  {
    title: "Apply Now",
    path: "/learning",
    cName: "dropdown-link",
  },
  {
    title: "Visit Campus",
    path: "/research",
    cName: "dropdown-link",
  },
];

export const Life = [
  {
    title: "Blogs",
    path: "blog",
    cName: "dropdown-link",
  },
  {
    title: "Student Organization",
    path: "student",
    cName: "dropdown-link",
  },
  {
    title: "Club And Activities",
    path: "/research",
    cName: "dropdown-link",
  },
  {
    title: "News",
    path: "news",
    cName: "dropdown-link",
  },
];
