import React from "react";
import "./about.css";
import Heading from "../common/heading/Heading";
import { useSelector } from "react-redux";
import {
  aboutUsParagraph,
  aboutUsTitle,
} from "../../utils/localizationConstants";
const Abouttt = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  return (
    <>
      <section
        className="homeAbout abou"
        style={{
          justifyContent: "start",
          alignContent: "start",
          // marginTop: "-80px",
        }}
        id="about"
      >
        <div className="container">
          <Heading
            subtitle={aboutUsTitle[selectedLanguage]}
            // title="explore our about us"
          />
          <div className="about-div">
            {/* <h3></h3> */}
            <p className="about-para" style={{ fontSize: "20px" }}>
              {aboutUsParagraph[selectedLanguage]}
            </p>
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/blog/photo_2023-12-26_17-34-40.jpg"
              }
              alt=""
              className="about-img"
              style={{ height: "500px" }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Abouttt;
