import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    selectedLanguage: "en",
  },
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    // selectLanguage: (state, action) => {
    //   state.selectedLanguage = action.payload;
    // },
  },
});

export const { setLanguage } = languageSlice.actions;
export const selectLanguage = (state) => state.language.selectedLanguage;
export default languageSlice.reducer;
